import React, { useEffect } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { EventTracker } from '../services/Analytics';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

export default function PromoModal({ modal, toggle, modalData }) {
  const history = useHistory();
  const [ritmo, setRitmo] = React.useState([]);
  const [clubx, setClubx] = React.useState([]);
  function goToEventRoute(url) {
    history.push(`/events/event/${url.slug}`);
    toggle();
  }

  useEffect(() => {
    async function getRitmo() {
      let Ritmo = modalData.filter((item) => item?.organization?._id === '643cf9203a18134da9c71763');
      let Clubx = modalData.filter((item) => item?.organization?._id === '643cf52b3a18134da9c71723');
      setRitmo(Ritmo.slice(0, 1));
      setClubx(Clubx.slice(0, 1));
    }
    getRitmo();
  }, [modalData]);

  const tracker = (props) => {
    EventTracker({
      category: 'Events',
      action: `Clicked on ${props.name} tickets`,
      label: props.event_date
    })
  }

  return (
    <>
      <div className='promo-modal'>
        <Modal
          show={modal}
          backdrop={true}
          onHide={toggle}
          keyboard={true}
          centered={false}
          size="lg"
          contentClassName="no-box-shadow"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Body className="no-box-shadow" style={{ paddingTop: 0, height: '100vh' }}>
            <Row>
              {ritmo.length > 0 && ritmo.map((item, index) => (
                <Col xs={clubx.length === 0 ? 12 : 6} key={index} className="text-center">
                  <h3 style={{ textTransform: 'uppercase', fontFamily: 'mainFont', marginBottom: 0 }} className="text-center org-name">{item?.organization?.name}</h3>
                  <h5 style={{ fontFamily: 'mainFont', marginTop: -10, fontWeight: 400 }} className="text-center org-days">Friday</h5>
                  <img style={{ maxHeight: 475 }} src={item?.promo?.story || item?.promo?.post} alt="Popup" />
                  <div className='popup-modal-footer margin-top-10'>
                    <Button variant="secondary" size="sm" onClick={toggle}>
                      No Thanks
                    </Button>
                    <Button size="sm" onClick={() => {
                      tracker({ name: item.name, event_date: moment(item.start_date).format('lll') });
                      goToEventRoute(item);
                    }} variant="primary">Tickets</Button>
                  </div>
                </Col>
              ))}
              {clubx.length > 0 && clubx.map((item, index) => (
                <Col xs={ritmo.length === 0 ? 12 : 6} key={index} className="text-center">
                  <h3 style={{ textTransform: 'uppercase', fontFamily: 'mainFont', marginBottom: 0 }} className="text-center org-name">{item?.organization?.name}</h3>
                  <h5 style={{ fontFamily: 'mainFont', marginTop: -10, fontWeight: 400 }} className="text-center org-days">Saturday</h5>
                  <img style={{ maxHeight: 475 }} src={item?.promo?.story || item?.promo?.post} alt="Popup" />
                  <div className='popup-modal-footer margin-top-10'>
                    <Button variant="secondary" size="sm" onClick={toggle}>
                      No Thanks
                    </Button>
                    <Button size="sm" onClick={() => {
                      tracker({ name: item.name, event_date: moment(item.start_date).format('lll') });
                      goToEventRoute(item);
                    }} variant="primary">Tickets</Button>
                  </div>
                </Col>
              ))}
              {/* {modalData.map((item, index) => (
                <Col key={index} className="text-center">
                  <img style={{ maxHeight: 800 }} src={item?.promo.story || item?.promo.post} alt="Popup" />
                  <div className='popup-modal-footer margin-top-10'>
                    <Button variant="secondary" size="sm" onClick={toggle}>
                      No Thanks
                    </Button>
                    <Button size="sm" onClick={() => {
                      tracker({ name: item.name, event_date: moment(item.start_date).format('lll') });
                      goToEventRoute(item);
                    }} variant="primary">Tickets</Button>
                  </div>
                </Col>
              ))} */}
            </Row>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
